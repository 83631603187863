import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FilterListIcon from '@material-ui/icons/FilterList';

import AttachFileIcon from '@material-ui/icons/AttachFile';

import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

import MaterialTable from 'material-table';

import { useNavigate } from "react-router-dom";

import { get_report_as_attach } from "../report/actions";

import {saveAs} from "file-saver";


const theme = createTheme({
  palette: {
    text: {
      primary: "#000000",
      secondary: "#46505A",
      positive: "#41C07C",
    },
    primary: {
      main: "rgba(255, 215, 47, 1)",
      darker: "#fff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
  components: {
      MuiTypography: {
      variants: [
        {
          props: {
            variant: "h3",
          },
          style: {
            fontSize: 28,
            lineHeight: "32px",
            fontWeight: 700,
            paddingLeft: 12,
            borderLeft: "solid 5px  rgba(62, 109, 165, 1)",
            marginLeft: -16,
          },
        }
      ]
    }
  }
});

function EnhancedTableHead(props) {
  const { headCells, classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected}{' '}{ numSelected === 1 ? 'звіт': ( numSelected > 1 && numSelected < 5 ) ? 'звіта': 'звітів' } можна завантажити!
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Перегляд. Для завантаження оберіть звіти.
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Завантажити">
          <IconButton aria-label="Download">
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowHover:{
    cursor: 'pointer',
  }
}));

const style_title = {
  fontSize: 28,
  lineHeight: "32px",
  fontWeight: 700,
  paddingLeft: 12,
  borderLeft: "solid 5px  rgba(62, 109, 165, 1)",
  //marginLeft: -16,
};

export default function EnhancedTable(props) {
 

 const { header, rows_data, title, detailview, key_id } = props;

 const title_prep = <div style={style_title}>{title}</div>

 const [ rows, setRows ] = useState([]);

 useEffect(()=>{setRows(()=>rows_data)} ,[rows_data.length]);
 
 const navigate = useNavigate();

 const classes = useStyles();

 const data_res = [];

 const uniq_eq_edr = {};

 const edr_search = '98765430',  period_search = '2 квартал', year_search = '2020';

 for(let row of rows) {

   if(!uniq_eq_edr[row.partyCode]) uniq_eq_edr[row.partyCode] = { 'centr': 0, 'region': 0 };
   if(row.parentCode == "") {
     uniq_eq_edr[row.partyCode]['region'] += 1; 
    } else {
      uniq_eq_edr[row.partyCode]['centr'] += 1;
   }
 }
 
 for(let el of Object.keys(uniq_eq_edr) ) {
   if(uniq_eq_edr[el].centr > 0 && uniq_eq_edr[el].region > 0 ) console.log(`EDRPOU ${el} PRESENT FOR CENTER[${uniq_eq_edr[el].centr} AND REGION[${uniq_eq_edr[el].region}]] `);
 }


 return (
    <div className={classes.root}>
    <ThemeProvider theme={theme}>
      <Paper className={classes.paper}>
      <MaterialTable
            title={title_prep}
            columns={header}
            data={rows}
            icons={{
              attach: () => <AttachFileIcon />
            }}
            parentChildData={(row,rows)=> {
              
              const result = rows.find(a=>a.partyCode === row.parentCode && a.period === row.period && a.year === row.year && a.parentCode === "" )

              return result;

            } }
            actions={[
              {
                icon: 'preview',
                tooltip: 'Переглянути звіт',
                onClick: (event, rowData)=> {
                  navigate(`${detailview}/${rowData[key_id]}` ) 
                }
              },
              {
                icon: (props)=><AttachFileIcon />,
                tooltip: 'Завантажити звіт',
                onClick: async (event, rowData)=> {
                  //navigate('/reportview/' + rowData.id )
                  let name_party = (typeof rowData.name) === "object" ? rowData.name.props.children : rowData.name;
                  const attach = await get_report_as_attach(rowData.id, detailview);
                  const name = detailview == "/reportview" ? 
                    ['Звіт',name_party.replace(' ', '_'), rowData.type.replace(' ', '_'), rowData.period.replace(' ','_'), rowData.year, '.xlsx'].join('_') :
                    ['Звіт виборчого фонду','.xlsx'].join('_'); 
                  saveAs(attach.data, name);
                }
              },
              rowData=>({
                icon: (rowData) => <AssignmentTurnedIn />,
                hidden: !rowData.result || !rowData.result.file || !rowData.result.file[0] || !rowData.result.file[0].url,
                tooltip: 'Завантажити висновок НАЗК',
                onClick: async (event, rowData) => {

                  const link_url =  rowData.result && rowData.result.file && rowData.result.file[0] && rowData.result.file[0].url;

                  if(!link_url) return;

                  const link = document.createElement('a');
                  link.href = link_url;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
      
              
              })
            ]}
            options={{
              actionsColumnIndex: -1,
              search: true, 
              filtering: true,
              pageSize: 10,
              pageSizeOptions: [10,20,50]
            }}
            localization={{
              header:{
                actions: 'Дії'
              },
              toolbar:{
                searchTooltip: 'Пошук',
                searchPlaceholder: 'Знайти' 
              },
              body:{
                  filterRow: {filterTooltip: 'Фільтр'}
              },
              pagination:{
                  labelDisplayedRows: '{from}-{to} зі {count}',
                  labelRowsPerPage: 'записів на сторінку:',
                  labelRowsSelect: 'записів на сторінку'
              }
            }}
      />
      </Paper>
      </ThemeProvider>
    </div>
  );
}
