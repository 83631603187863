import React, { Component } from 'react';
import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Paper from '@material-ui/core/Paper';

import EnhancedTable from './reportlist';

import { get_records } from './report/actions';

import '../table_party.css';

import Container from '@material-ui/core/Container';



import { Header } from "./header";

import Footer from "./Footer";

function createData(name, type, year, period, types, date, key, id, partyCode, parentCode, result, resultstatus) {
    return { name, type, year, period, types, date, key, id, partyCode, parentCode, result, resultstatus };
}


const styles = theme => ({
    table: {
      maxWidth: 1650,
      minHeight: 200,
      marginTop: 20,
    },
    head:{
        fontWeight:700
    }
  });

// name, type, year, period, date, key, id

const headerTables = [
    {field: 'name',  title: 'Повне найменування політичної партії'},
    {field: 'type',  title:'Центральний або регіональний'},
    {field: 'year',  title:'Рік'},
    {field: 'period',  title:'Період'},
    {field: 'types',  title:'Тип звіту'},
    {field: 'date',  title:'Дата', customSort: (a,b)=>{

        const _as = a.date.split('.');
        const _bs = b.date.split('.');

        const _a = new Date(_as[2], _as[1]-1, _as[0]).getTime();
        const _b = new Date(_bs[2], _bs[1]-1, _bs[0]).getTime();

        if (_b < _a) {
            return -1;
          }
        if (_b > _a) {
            return 1;
        }
        
        return 0;

    }},
    {field: 'resultstatus', title:'Висновок НАЗК'}
];



class Reports extends Component {

    constructor(props){
        super(props);
        this.state = {
            data:[]
        }
    }


    componentDidMount(){
        get_records()
        .then( data=> data.data.sort( (a,b) =>{
            if(a.date < b.date ) return 1;
            if(a.date > b.date ) return -1;
            return 0;
        }) )
        .then( data=>data.map( (row,indx)=>{ row['key']=indx; if(row.parentCode != '') row.partyName = <li style={{marginLeft: '15px'}}>{row.partyName}</li>; ; row['resultstatus'] = row.result && row.result.date ? new Date(row.result.date).toLocaleDateString('uk') : row.date !== "-- -- --" ? 'На розгляді': "-"; if(row.date == "-- -- --") row.types = "Додаток"; return row;} ))
        .then( data => this.setState( { data: data} ) )
        .catch( err=> console.log(err));
    }
    

    render(){ 

        const { classes } = this.props;

        const data = this.state.data.map( r=>createData(
            r.partyName, 
            r.officeType, 
            r.year, 
            r.period, 
            r.types, 
            !isNaN( (new Date(r.date) ).getTime()) && new Date(r.date).toLocaleDateString('uk') || r.date, 
            r.key, 
            r.id, 
            r.partyCode, 
            r.parentCode, 
            r.result, 
            r.resultstatus && (r.resultstatus !== "На розгляді") ? " Завершено": r.resultstatus ) );

        return(
        
        <React.Fragment>
            
            <CssBaseline />
        <Header short={true} />
        <Container>
            <Paper className={classes.table}>
            <EnhancedTable 
                header = {headerTables}
                rows_data = {data}
                title="Квартальна звітність партій"
                detailview="/reportview"
                key_id="id"
            />  
        </Paper>
        </Container>
        <Footer />
        </React.Fragment>
    )
    }


}

export default withStyles(styles)(Reports);